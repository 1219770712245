import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UpcomingEvents from './UpcomingEvents';
import NotificationsCard from './NotificationsCard';
import Catalog from './Catalog';
import PageContainer from '@/components/Container/PageContainer';
import { Button } from '@/components/UI/Button';
import { H4 } from '@/components/UI/Typography/Heading';
import {
  AccountVerifiedBadge,
  CreditReportBadge,
  ECertBadge,
  IdentificationCheckBadge,
  SupplementalInfoBadge,
  UserInfoBadge,
} from '@/components/UI/Badge';

import { HomeWrapper, Notice, Icons, ExpiryDateNotification } from './style';
import { DealsChart, DocumentsChart, MembersChart, SubscriptionChart } from './Charts';
import { Flex } from '@/layout';
import useResize from '@/hooks/useResize';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';

const Home = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResize();
  const [showNewFeature, setShowNewFeature] = useState(true);
  const [isExpiring, setIsExpiring] = useState(false);
  const { userData } = useAppSelector(getAuthSelector);

  useEffect(() => {
    if (userData.plan.expiration_date) {
      const expiryDate = new Date(userData.plan.expiration_date);
      // 確認過期日是否在一個月內
      const now = new Date();
      const diff = expiryDate.getTime() - now.getTime();
      const days = diff / (1000 * 60 * 60 * 24);
      if (days < 30) {
        setIsExpiring(true);
      }
    }
  }, []);

  return (
    <PageContainer title={''}>
      {isExpiring && (
        <Notice isTablet={isTablet} isMobile={isMobile}>
          <H4 className="text">{t('pages.home.expriedNotice')}</H4>
          <Link to="/profile/subscriptionDetail">
            <Button>{t('buttons.go')}</Button>
          </Link>
        </Notice>
      )}
      {isTablet && (
        <Notice isTablet={isTablet} isMobile={isMobile}>
          <H4 className="text">{t('pages.home.sizeRecommend')}</H4>
        </Notice>
      )}
      <Notice isTablet={isTablet} isMobile={isMobile}>
        <Icons>
          <UserInfoBadge width={24} align={'center'} />
          <SupplementalInfoBadge width={24} align={'center'} />
          <IdentificationCheckBadge width={24} align={'center'} />
          <ECertBadge width={24} align={'center'} />
          {/* <CreditReportBadge width={24} align={'center'} /> */}
          <AccountVerifiedBadge width={24} align={'center'} />
        </Icons>
        <H4 className="text">{t('pages.home.filling')}</H4>
        <Link to="/profile/edit">
          <Button>{t('buttons.go')}</Button>
        </Link>
      </Notice>
      <HomeWrapper isTablet={isTablet} isMobile={isMobile}>
        <Flex flexDirection={isMobile ? 'column' : 'row'} maxWidth={1215} width={'100%'} gridGap={15}>
          <UpcomingEvents />
          <NotificationsCard />
        </Flex>
      </HomeWrapper>
      <HomeWrapper isTablet={isTablet} isMobile={isMobile}>
        <Catalog />
      </HomeWrapper>
      {showNewFeature && (
        <HomeWrapper isTablet={isTablet} isMobile={isMobile}>
          <Flex flexDirection={'column'} gridGap={15}>
            <DealsChart />
            <MembersChart />
          </Flex>
          <SubscriptionChart />
          <DocumentsChart />
        </HomeWrapper>
      )}
    </PageContainer>
  );
};

export default Home;
