import styled from 'styled-components';

const Container = styled.div``;

const BannerWrapper = styled.div`
  width: 100%;
  background-image: url('/images/yellowpage/Group 2414.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 15px;

  .mask {
    width: 100%;
    height: 100%;
    padding: 150px 0;
    border-radius: 15px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(57, 57, 57, 0.6);
  }
`;

const PSDWrapper = styled.div`
  width: 100%;
  background-image: url('/images/yellowpage/PSD_BG.png');
  background-position: center;
  background-size: cover;
  border-radius: 15px;

  .mask {
    width: 100%;
    height: 100%;
    padding: 150px 0;
    border-radius: 15px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(57, 57, 57, 0.6);
  }
`;

const SearchBarWrapper = styled.div`
  display: flex;
  margin-top: 45px;
  width: 100%;
`;

const SearchIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${(p) => (p.isMobile ? '100%' : '40px')}};
  height: 40px;
  background: ${(p) => p.theme.colors.secondary400};
  color: ${(p) => p.theme.colors.light};
  border-radius: 5px;
  display: flex;

  > img,
  span {
    margin: auto;
  }

  &.reset {
    background: ${(p) => p.theme.colors.gray200};
    &:hover {
      background: ${(p) => p.theme.colors.gray300};
    }
  }
`;

const AdvancedButton = styled.button`
  width: 200px;
  height: 40px;
  filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.base};
  line-height: 19px;
  color: ${(p) => p.theme.colors.light};
  cursor: pointer;
`;
const SectionContainer = styled.div`
  width: 100%;
  max-width: 1190px;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 50px;
`;

const Number = styled.span`
  color: ${(p) => p.theme.colors.secondary500};
`;
const CatalogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Card = styled.div`
  width: 230px;
  padding: 25px;
  text-align: center;
  background: rgba(109, 131, 196, 0.1);
  border-radius: 5px;
  margin-bottom: 5px;

  > div {
    margin-top: 10px;
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.base};
    line-height: 19px;
    color: ${(p) => p.theme.colors.gray500};
  }
`;

const BgBlockWrapper = styled.div`
  width: 390px;
  border-radius: 5px;

  > .mask {
    width: 100%;
    border-radius: 5px;
    padding: 75px 0;
    background: rgba(33, 33, 33, 0.6);
    text-align: center;
    color: ${(p) => p.theme.colors.light};
  }

  > .mask .title {
    font-weight: 400;
    font-size: ${(p) => p.theme.fontSizes.lg};
    line-height: 38px;
  }

  > .mask .subTitle {
    font-weight: 700;
    font-size: ${(p) => p.theme.fontSizes.md};
    line-height: 23px;
  }
`;

const LogoWrapper = styled.div`
  width: 230px;
  padding: 44px 0;
  text-align: center;
  background: ${(p) => p.theme.colors.light};
  border-radius: 5px;
  margin-bottom: 5px;

  > .fakeLogo {
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
    color: ${(p) => p.theme.colors.secondary500};
  }
`;

const SearchButton = styled.div`
  width: 170px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  color: ${(p) => p.theme.colors.light};
  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
`;

const CardWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  padding: 25px;
  display: flex;
  gap: 30px;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  margin-bottom: 15px;
  ${(p) => p.theme.outsideShadowW01};

  .detail {
    flex: 1;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: ${(p) => p.theme.colors.gray400};

  > .col {
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  > .col .info {
  }
`;

const Content = styled.div<{ isMobile: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    gap: 8px;
    margin-bottom: 15px;
  }

  > p {
    color: ${(p) => p.theme.colors.gray500};
    display: flex;
    flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};

    a {
      color: ${(p) => p.theme.colors.secondary500};
      text-decoration: underline;
      margin-left: ${(p) => (p.isMobile ? '0' : '10px')};
      margin-bottom: ${(p) => (p.isMobile ? '10px' : '0')};
    }
  }

  .keyword {
    color: ${(p) => p.theme.colors.gray300};
    line-height: 1;
    margin-top: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
  }
`;

const CardTitle = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  font-weight: 700;
  font-size: ${(p) => p.theme.fontSizes.lg};
  line-height: 38px;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 12px;
  width: ${(p) => (p.isMobile ? '205%' : p.isTablet ? '350px' : '100%')};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
`;

const IconBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 100%;
  padding: 8px;
  background: ${(p) => p.theme.colors.primary500};
  border-radius: 5px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.light};

  &.disable {
    background: ${(p) => p.theme.colors.gray300};
  }

  > a,
  > button {
    margin: auto;
    display: flex;
    color: ${(p) => p.theme.colors.light};
  }
`;

const LogoBox = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: ${(p) => (p.isMobile ? '100%' : p.isTablet ? '200px' : '250px')};
  height: ${(p) => (p.isMobile ? '200px' : p.isTablet ? 'unset' : '170px')};
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;
`;

const PageBtnWrapper = styled.button`
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.gray100};

  &.disabled {
    cursor: unset;
    background: ${(p) => p.theme.colors.gray200};
  }

  &:hover {
    border: 1px solid ${(p) => p.theme.colors.gray500};
  }
`;

const SearchInputWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  align-items: center;
  gap: 10px;

  > .inputWrap {
    width: 100%;
    flex: 1;
    position: relative;
    z-index: 0;

    &.map {
      max-width: ${(p) => (p.isMobile ? '300px' : '282px')};
    }

    &.options {
      max-width: ${(p) => (p.isMobile ? '100%' : '150px')};
    }
  }
`;

export {
  BannerWrapper,
  SearchBarWrapper,
  SearchIconWrapper,
  AdvancedButton,
  SectionContainer,
  Number,
  CatalogWrapper,
  Card,
  BgBlockWrapper,
  LogoWrapper,
  SearchButton,
  CardWrapper,
  Row,
  CardTitle,
  IconBtn,
  Content,
  LogoBox,
  PageBtnWrapper,
  SearchInputWrapper,
  PSDWrapper,
};
