import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentLang from '@/hooks/useCurrentLang';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SearchBarInputsWrapper } from '@/components/Container/AuthContainer/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  IconButton,
  TextField,
  FormControlLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import category from '@/data/select/searchBarCategoryLang';
// import { category, product } from '@/data/select';
import { converToMultiLangSelectData } from '@/utils/convert';
import { PSDWrapper, SearchBarWrapper, SearchIconWrapper, SearchButton, SearchInputWrapper } from './style';
import MultiSelect from '@/components/UI/Select/MultiSelect';
import useResize from '@/hooks/useResize';
import { Flex } from '@/layout';
import { H1 } from '@/components/UI/Typography/Heading';
import useCountryDetail from '@/hooks/useCountryDetail';

interface CheckBoxProps {
  value: string;
  text: string;
  selected: boolean;
}

interface FormProps {
  acc_coname: string;
  acc_country: string[];
  province: string[];
  city: string[];
  lawFirmSectorSpecialty: string[];
  practiceAreas: string[];
  category: string[];
}

interface SearchBarProps {
  setCurrentCategory: Dispatch<SetStateAction<string>>;
}

const CNSearchBar = ({ setCurrentCategory }: SearchBarProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const { isTablet, isMobile } = useResize();
  const title = t('lawFirmsList');
  const navigate = useNavigate();
  const provinceCheckboxList = t('mapOptions.province', { returnObjects: true });
  const cityCheckboxList = t('mapOptions.city.Guangdong', { returnObjects: true });
  const [open, setOpen] = useState(false);
  const { handleSubmit, control, setValue, watch } = useForm<FormProps>();
  const [provinceList, setProvinceList] = useState<CheckBoxProps[]>([]);
  const [cityList, setCityList] = useState<CheckBoxProps[]>([]);
  const [categoryList, setCategoryList] = useState<CheckBoxProps[]>(converToMultiLangSelectData(category, lang));
  const [sectorOptions, setSectorOptions] = useState<CheckBoxProps[]>(
    t('mapOptions.lawFirmSectorSpecialty', { returnObjects: true })
  );
  const [areasOptions, setAreasOptions] = useState<CheckBoxProps[]>(
    t('mapOptions.practiceAreas', { returnObjects: true })
  );

  const [showSelector, setShowSelector] = useState(false);

  // 控制收合面板不同選項的顯示
  const [showProvince, setShowProvince] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showSector, setShowSector] = useState(false);
  const [showAreas, setShowAreas] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  const watchAccCountry = watch('acc_country');

  useEffect(() => {
    setProvinceList([...provinceCheckboxList.data]);
    setCityList([...cityCheckboxList.data]);
    setValue('acc_country', [] as string[]);
    setSectorOptions(t('mapOptions.lawFirmSectorSpecialty', { returnObjects: true }));
    setAreasOptions(t('mapOptions.practiceAreas', { returnObjects: true }));
  }, [t]);

  const onSubmit = handleSubmit(async (data) => {
    const provinceQuery = provinceList
      ?.filter((item) => item.selected)
      .map((item) => item.value)
      .join(',');

    const cityQuery = cityList
      ?.filter((item) => item.selected)
      .map((item) => item.value)
      .join(',');

    let queryString = '';

    if (provinceQuery) {
      queryString += `&province=${provinceQuery}`;
    }
    if (cityQuery) {
      queryString += `&city=${cityQuery}`;
    }

    if (categoryList) {
      const selectedCategory = categoryList.filter((item) => item.selected);
      const categoryQuery = selectedCategory.map((item) => item.value).join(',');
      if (categoryQuery) {
        queryString += `&category=${categoryQuery}`;
        setCurrentCategory(selectedCategory[0]?.text);
      }
    }
    if (data.acc_coname) {
      queryString += `&acc_coname=${data.acc_coname}`;
    }

    if (data.acc_country) {
      queryString += `&acc_country=${data.acc_country.join(',')}`;
    }

    if (data.lawFirmSectorSpecialty && data.lawFirmSectorSpecialty.length !== 0) {
      queryString += `&lawFirmSectorSpecialty=${data.lawFirmSectorSpecialty.join(',')}`;
    }
    if (data.practiceAreas && data.practiceAreas.length !== 0) {
      queryString += `&practiceAreas=${data.practiceAreas.join(',')}`;
    }
    setOpen(false);

    navigate(`/professionalService?per_page=10&page=1${queryString}`);
  });

  const openHandler = (type: string) => {
    // 檢查若點擊的按鈕已經是open的, 則收合
    if (
      (open && type === 'province' && showProvince) ||
      (open && type === 'city' && showCity) ||
      (open && type === 'sector' && showSector) ||
      (open && type === 'areas' && showAreas) ||
      (open && type === 'category' && showCategory)
    ) {
      setOpen(false);
      return;
    }

    setOpen(true);

    // 依照點擊的按鈕, 顯示對應的選項, 其餘隱藏
    switch (type) {
      case 'province':
        setShowProvince(true);
        setShowCity(false);
        setShowSector(false);
        setShowAreas(false);
        setShowCategory(false);
        break;
      case 'city':
        setShowProvince(false);
        setShowCity(true);
        setShowSector(false);
        setShowAreas(false);
        setShowCategory(false);
        break;
      case 'sector':
        setShowProvince(false);
        setShowCity(false);
        setShowSector(true);
        setShowAreas(false);
        setShowCategory(false);
        break;
      case 'areas':
        setShowProvince(false);
        setShowCity(false);
        setShowSector(false);
        setShowAreas(true);
        setShowCategory(false);
        break;
      case 'category':
        setShowProvince(false);
        setShowCity(false);
        setShowSector(false);
        setShowAreas(false);
        setShowCategory(true);
        break;
    }
  };

  // 點擊畫面中的按鈕會有自動收合的bug, 故拉出額外function呼叫sumbit
  const searchSubmit = () => {
    onSubmit();
  };

  const resetSearch = () => {
    setValue('acc_coname', '');
    setValue('acc_country', [] as string[]);
    setValue('lawFirmSectorSpecialty', [] as string[]);
    setValue('practiceAreas', [] as string[]);
    setValue('category', [] as string[]);
    setCurrentCategory('');
    setValue('province', [] as string[]);
    setValue('city', [] as string[]);
    setProvinceList([...provinceCheckboxList.data]);
    setCityList([...cityCheckboxList.data]);
    setSectorOptions(t('mapOptions.lawFirmSectorSpecialty', { returnObjects: true }));
    setAreasOptions(t('mapOptions.practiceAreas', { returnObjects: true }));
    navigate(`/professionalService?per_page=10&page=1`);
  };

  const provinceCheckBoxHandler = (index: number) => {
    if(!provinceList) return;
    // 調整按鈕顯示狀態
    provinceList[index].selected = !provinceList[index].selected;
    setProvinceList([...provinceList]);
    setValue(
      'province',
      provinceList.filter((item) => item.selected).map((item) => item.value)
    );
  };

  const cityCheckboxHandler = (index: number) => {
    if (!cityList) return;
    cityList[index].selected = !cityList[index].selected;
    setCityList([...cityList]);
    setValue(
      'city',
      cityList.filter((item) => item.selected).map((item) => item.value)
    );
  };

  const categoryCheckboxHandler = (index: number) => {
    if (!categoryList) return;
    // category僅能夠單選, 故點擊後將其他選項的selected改為false
    categoryList[index].selected = !categoryList[index].selected;
    categoryList.forEach((item, i) => {
      if (i !== index) {
        item.selected = false;
      }
    });
    setCategoryList([...categoryList]);
    setValue(
      'category',
      categoryList.filter((item) => item.selected).map((item) => item.value)
    );
  };

  const sectorCheckboxHandler = (index: number) => {
    sectorOptions[index].selected = !sectorOptions[index].selected;
    setSectorOptions([...sectorOptions]);
    setValue(
      'lawFirmSectorSpecialty',
      sectorOptions.filter((item) => item.selected).map((item) => item.value)
    );
  };

  const areasCheckboxHandler = (index: number) => {
    areasOptions[index].selected = !areasOptions[index].selected;
    setAreasOptions([...areasOptions]);
    setValue(
      'practiceAreas',
      areasOptions.filter((item) => item.selected).map((item) => item.value)
    );
  };

  // 監視category如果value為Legal Services, 則顯示sector和areas的選項
  useEffect(() => {
    if (watch('category') && watch('category').includes('Legal Services')) {
      setShowSelector(true);
    } else {
      setShowSelector(false);
      // 清空sector和areas的選項
      setValue('lawFirmSectorSpecialty', []);
      setValue('practiceAreas', []);
    }
  }, [watch('category')]);

  // 監視lang變化, 若lang變化則重新設定categoryList
  useEffect(() => {
    const newOptions = converToMultiLangSelectData(category, lang);
    setCategoryList(newOptions);
    // 依照watch('category')的資料將對應value的selected改為true
    if (watch('category')) {
      const currentCategory = watch('category');
      const newData = newOptions.map((item) => {
        if (currentCategory.includes(item.value)) {
          return {
            ...item,
            selected: true,
          };
        }
        return {
          ...item,
          selected: false,
        };
      });
      setCategoryList([...newData]);
      setCurrentCategory(newData.filter((item) => item.selected)[0]?.text);
    }
    // 清空sector和areas的選項
    setValue('lawFirmSectorSpecialty', []);
    setValue('practiceAreas', []);
  }, [lang]);

  return (
    <PSDWrapper>
      <div className="mask">
        <H1 style={{ color: '#fff' }}>{t('lawFirmsList')}</H1>
        <SearchBarWrapper>
          <form onSubmit={onSubmit} style={{ width: '100%' }}>
            <Accordion expanded={open} defaultExpanded={false} style={{ maxWidth: 995, width: '95%', margin: 'auto' }}>
              <AccordionSummary aria-label="Expand" style={{ background: 'transparent' }}>
                <SearchBarInputsWrapper>
                  <SearchInputWrapper isTablet={isTablet} isMobile={isMobile}>
                    <div className="inputWrap map">
                      <Controller
                        control={control}
                        defaultValue=""
                        name={'acc_coname'}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            placeholder={t('form.lawFirmsName.placeholder')}
                            variant="outlined"
                            value={value}
                            sx={{ width: '100%', background: '#FFF', borderRadius: '5px' }}
                            onChange={onChange}
                            size="small"
                            error={!!error}
                            helperText={error ? error.message : null}
                            autoComplete="off"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                searchSubmit();
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="inputWrap">
                      <MultiSelect
                        control={control}
                        variant={'outlined'}
                        size={'small'}
                        data={useCountryDetail('name')}
                        inputLabel={t('form.searchLocation.label')}
                        selectLabel={t('form.searchLocation.label')}
                        name={'acc_country'}
                      />
                    </div>
                    {
                      watchAccCountry && watchAccCountry.includes('China') && (
                        <>
                          <div className="inputWrap map" onClick={() => openHandler('province')}>
                            <MultiSelect
                              control={control}
                              variant={'outlined'}
                              size={'small'}
                              data={provinceList}
                              inputLabel={t('pages.yellowPage.CNSearchBar.chinaProvince')}
                              selectLabel={t('pages.yellowPage.CNSearchBar.chinaProvince')}
                              name={'province'}
                              readOnly={true}
                            />
                          </div>

                          <div className="inputWrap map" onClick={() => openHandler('city')}>
                            <MultiSelect
                              control={control}
                              variant={'outlined'}
                              size={'small'}
                              data={cityList}
                              inputLabel={t('pages.yellowPage.CNSearchBar.cities')}
                              selectLabel={t('pages.yellowPage.CNSearchBar.cities')}
                              name={'city'}
                              readOnly={true}
                            />
                          </div>
                        </>
                      )
                    }


                    <div className="inputWrap map" onClick={() => openHandler('category')}>
                      <MultiSelect
                        control={control}
                        variant={'outlined'}
                        size={'small'}
                        data={converToMultiLangSelectData(category, lang)}
                        inputLabel={t('info.natureOfBusiness')}
                        selectLabel={t('info.natureOfBusiness')}
                        name={'category'}
                        readOnly={true}
                      />
                    </div>
                    {showSelector && (
                      <>
                        <div className="inputWrap options" onClick={() => openHandler('sector')}>
                          <MultiSelect
                            control={control}
                            variant={'outlined'}
                            size={'small'}
                            data={sectorOptions}
                            inputLabel={t('form.lawFirmSectorSpecialty.label')}
                            selectLabel={t('form.lawFirmSectorSpecialty.label')}
                            name={'lawFirmSectorSpecialty'}
                            readOnly={true}
                          />
                        </div>
                        <div className="inputWrap options" onClick={() => openHandler('areas')}>
                          <MultiSelect
                            control={control}
                            variant={'outlined'}
                            size={'small'}
                            data={areasOptions}
                            inputLabel={t('form.practiceAreas.label')}
                            selectLabel={t('form.practiceAreas.label')}
                            name={'practiceAreas'}
                            readOnly={true}
                          />
                        </div>
                      </>
                    )}
                    <Flex gridGap={'5px'} style={{ width: isMobile ? '100%' : 'unset' }}>
                      <IconButton
                        aria-label="search"
                        style={{ padding: '0', margin: '0', width: isMobile ? '50%' : 'unset' }}
                        onClick={(e) => {
                          e.preventDefault();
                          searchSubmit();
                        }}
                      >
                        <SearchIconWrapper isMobile={isMobile}>
                          <img width={32} src="/images/yellowpage/search.svg" alt="" />
                        </SearchIconWrapper>
                      </IconButton>
                      <IconButton
                        aria-label="search"
                        style={{ padding: '0', margin: '0', width: isMobile ? '50%' : 'unset' }}
                        onClick={(e) => {
                          e.preventDefault();
                          resetSearch();
                        }}
                      >
                        <SearchIconWrapper isMobile={isMobile} className="reset">
                          <span>X</span>
                        </SearchIconWrapper>
                      </IconButton>
                    </Flex>
                  </SearchInputWrapper>
                </SearchBarInputsWrapper>
              </AccordionSummary>
              <AccordionDetails>
                {/* 若acc_country查詢為China時才顯示省份和城市的checkbox選項 */}
                {showProvince && watchAccCountry && watchAccCountry.includes('China') && (
                  <Box>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '19px',
                        color: '#212121',
                      }}
                    >
                      {t('pages.yellowPage.CNSearchBar.chinaProvince')}
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                      {provinceList &&
                        provinceList.map((province, index) => (
                          <FormControlLabel
                            key={province.text + index}
                            control={
                              <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#7EB442',
                                  },
                                }}
                                onChange={() => {
                                  provinceCheckBoxHandler(index);
                                }}
                                checked={province.selected}
                              />
                            }
                            label={<label htmlFor="">{province.text}</label>}
                          />
                        ))}
                    </div>
                  </Box>
                )}

                {showCity && watchAccCountry && watchAccCountry.includes('China') && (
                  <Box marginTop={'15px'}>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '19px',
                        color: '#212121',
                      }}
                    >
                      {t('pages.yellowPage.CNSearchBar.cities')}
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                      {cityList.map((city, index) => (
                        <FormControlLabel
                          key={city.text + index}
                          control={
                            <Checkbox
                              sx={{
                                '&.Mui-checked': {
                                  color: '#7EB442',
                                },
                              }}
                              onChange={() => {
                                cityCheckboxHandler(index);
                              }}
                              checked={city.selected}
                            />
                          }
                          label={<label htmlFor="">{city.text}</label>}
                        />
                      ))}
                    </div>
                  </Box>
                )}

                {
                  showCategory && (
                    <Box >
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          lineHeight: '19px',
                          color: '#212121',
                        }}
                      >
                        {t('info.natureOfBusiness')}
                      </div>

                      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                        {categoryList.map((category, index) => (
                          <FormControlLabel
                            key={category.text + index}
                            control={
                              <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#7EB442',
                                  },
                                }}
                                onChange={() => {
                                  categoryCheckboxHandler(index);
                                }}
                                checked={category.selected}
                              />
                            }
                            label={<label htmlFor="">{category.text}</label>}
                          />
                        ))}
                      </div>
                    </Box>
                  )
                }

                {showSector && (
                  <Box>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '19px',
                        color: '#212121',
                      }}
                    >
                      {t('form.lawFirmSectorSpecialty.label')}
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                      {sectorOptions.map((sector, index) => (
                        <FormControlLabel
                          key={sector.text + index}
                          control={
                            <Checkbox
                              sx={{
                                '&.Mui-checked': {
                                  color: '#7EB442',
                                },
                              }}
                              onChange={() => {
                                sectorCheckboxHandler(index);
                              }}
                              checked={sector.selected}
                            />
                          }
                          label={<label htmlFor="">{sector.text}</label>}
                        />
                      ))}
                    </div>
                  </Box>
                )}

                {showAreas && (
                  <Box >
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '19px',
                        color: '#212121',
                      }}
                    >
                      {t('form.practiceAreas.label')}
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                      {areasOptions.map((areas, index) => (
                        <FormControlLabel
                          key={areas.text + index}
                          control={
                            <Checkbox
                              sx={{
                                '&.Mui-checked': {
                                  color: '#7EB442',
                                },
                              }}
                              onChange={() => {
                                areasCheckboxHandler(index);
                              }}
                              checked={areas.selected}
                            />
                          }
                          label={<label htmlFor="">{areas.text}</label>}
                        />
                      ))}
                    </div>
                  </Box>
                )}

                <SearchButton
                  style={{ backgroundColor: '#ADD747', cursor: 'pointer', marginTop: 45 }}
                  onClick={(e) => {
                    searchSubmit();
                  }}
                >
                  <img width={32} src="/images/yellowpage/search.svg" alt="" />
                  {t('buttons.search')}
                </SearchButton>
              </AccordionDetails>
            </Accordion>
          </form>
        </SearchBarWrapper>
      </div>
    </PSDWrapper>
  );
};

export default CNSearchBar;
