import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';
import { generateChatToUser } from '@/api/chat.api';
import gradeGenerator from '@/utils/gradeGenerator';

import { Box, Flex } from '@/layout';
import {
  UserInfoBadge,
  SupplementalInfoBadge,
  IdentificationCheckBadge,
  ECertBadge,
  CreditReportBadge,
  AccountVerifiedBadge,
} from '@/components/UI/Badge';
import { CardTitle, CardWrapper, Row, IconBtn, Content, SectionContainer, LogoBox, PageBtnWrapper } from './style';
import categoryGenerator from '@/utils/categoryGenerator';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { Page } from '@/components/Icon';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H1 } from '@/components/UI/Typography/Heading';
import useResize from '@/hooks/useResize';

interface ListProps {
  showData: boolean;
  data?: MemberProfile[];
  recommendData?: MemberProfile[];
  totalData: number;
  perPage: number;
  page: number;
  lastPage: number;
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  type?: 'YellowPage' | 'LawFirmsList';
  currentCategory: string;
}

interface Verification {
  person: boolean;
  supplemental: boolean;
  identification: boolean;
  eCert: boolean;
  creditReport: boolean;
  accountVerified: boolean;
}

const List = ({
  showData,
  data = [],
  recommendData = [],
  totalData,
  page,
  perPage,
  setPage,
  lastPage,
  total,
  type = 'YellowPage',
  currentCategory,
}: ListProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useAppSelector(getAuthSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isTablet, isMobile } = useResize();

  const pageHandler = (value: number, action: string) => {
    if (action === 'prev') {
      if (value <= 0) return;
    }
    if (action === 'next') {
      if (totalData - page * perPage < 0) return;
    }
    setPage(value);
    searchParams.set('page', value.toString());
    searchParams.set('per_page', perPage.toString());
    setSearchParams(searchParams);
    window.scrollTo({
      top: 600,
      behavior: 'smooth',
    });
  };

  const badgeGenerater = (badgeAry: Verification) => {
    const badges: React.ReactNode[] = [];
    // badgeAry.creditReport && badges.push(<CreditReportBadge width={24} align={'center'} />);
    badgeAry.eCert && badges.push(<ECertBadge width={24} align={'center'} />);
    badgeAry.identification && badges.push(<IdentificationCheckBadge width={24} align={'center'} />);
    badgeAry.person && badges.push(<UserInfoBadge width={24} align={'center'} />);
    badgeAry.supplemental && badges.push(<SupplementalInfoBadge width={24} align={'center'} />);

    return badges;
  };

  const goToChat = async (user_id: number) => {
    try {
      const res: ChatGenerateResponse = (await generateChatToUser({ user_id })).data;
      if (res.success) {
        navigate(`/chat?room=${res.data.id}`);
      }
    } catch (err) {}
  };

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      width: 'unset',
      maxWidth: 'unset',
      fontSize: theme.fontSizes.base,
      whiteSpace: 'nowrap',
    },
  }));

  const checkWebsiteLink = (link: string) => {
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      return 'http://' + link;
    }
  };

  // 確認是否顯示徽章, 此處為陣列mapping用, 單純回傳true/false即可
  const checkShowVerifiedBadge = (data: MemberProfile) => {
    // 有子母公司的情況需子公司與母公司皆有驗證才將驗證badge顯示
    if (data.verification.accountVerified && data.childCompanies) {
      const childVerified = data.childCompanies.every((item) => item.verification.accountVerified);
      if (childVerified) {
        return true;
      }
      return false;
    }

    // 梅子公司的情況只需母公司有驗證就將驗證badge顯示
    if (data.verification.accountVerified) {
      return true;
    }
    return false;
  };

  return (
    <SectionContainer>
      {type !== 'YellowPage' && <H1>{currentCategory}</H1>}
      {data.length > 0 && showData && (
        <Flex paddingBottom={'20px'} justifyContent={'space-between'}>
          <Text color={theme.colors.gray300}>
            {t('buttons.showing')} {(page - 1) * perPage + 1} {t('buttons.to')}{' '}
            {totalData - page * perPage < 0 ? totalData : page * perPage} {t('buttons.of')} {totalData}{' '}
            {t('buttons.records')}
          </Text>
          <Flex gridGap={'10px'}>
            <PageBtnWrapper className={page === 1 ? 'disabled' : ''} onClick={() => pageHandler(page - 1, 'prev')}>
              <Page.PagePrevSVG />
            </PageBtnWrapper>
            {totalData - page * perPage > 0 && (
              <PageBtnWrapper onClick={() => pageHandler(page + 1, 'next')}>
                <Page.PageNextSVG />
              </PageBtnWrapper>
            )}
          </Flex>
        </Flex>
      )}
      {showData &&
        data.map((item, index) => (
          <CardWrapper isMobile={isMobile} key={'company' + index}>
            <LogoBox isMobile={isMobile} isTablet={isTablet}>
              <img height={'100%'} width={'100%'} src={item.acc_logo ?? '/images/default_company_logo.jpg'} style={{ objectFit: 'contain' }} alt="" />
            </LogoBox>
            <Content isMobile={isMobile}>
              {item?.acc_coname && item.acc_coname.length > 22 ? (
                <Flex gridGap={'5px'}>
                  <CustomTooltip title={item.acc_coname} placement="top">
                    <CardTitle isMobile={isMobile} isTablet={isTablet} >{`${item.acc_coname.slice(0, 20)}...`}</CardTitle>
                  </CustomTooltip>
                  {checkShowVerifiedBadge(item) && (
                    <Flex marginBottom={'10px'}>
                      <AccountVerifiedBadge width={24} align={'center'} />
                    </Flex>
                  )}
                </Flex>
              ) : (
                <Flex gridGap={'5px'}>
                  <CardTitle isMobile={isMobile} isTablet={isTablet}>{item?.acc_coname}</CardTitle>
                  {checkShowVerifiedBadge(item) && (
                    <Flex marginBottom={'10px'}>
                      <AccountVerifiedBadge width={24} align={'center'} />
                    </Flex>
                  )}
                </Flex>
              )}
              <span>{badgeGenerater(item.verification)}</span>
              {isTablet && (
                <Row>
                  {/* <Flex alignItems={'flex-end'}>
                    {gradeGenerator(item.stars).map((star, index) => (
                      <img width="33px" key={'grade' + item.acc_coname + index} src={star} alt="grade" />
                    ))}
                    {` (${item.rating_count})`}
                  </Flex> */}
                  <Flex marginLeft={'auto'} gridGap={10}>
                    {item.id !== userData.id ? (
                      <IconBtn onClick={() => goToChat(item.id)}>
                        <img src="/images/yellowpage/chat.svg" alt="" />
                      </IconBtn>
                    ) : (
                      <IconBtn className="disable">
                        <img src="/images/yellowpage/chat.svg" alt="" />
                      </IconBtn>
                    )}
                    {item.acc_website && (
                      <IconBtn>
                        <a href={checkWebsiteLink(item.acc_website)} target="_blank" rel="noreferrer">
                          <img src="/images/yellowpage/global.svg" alt="" />
                        </a>
                      </IconBtn>
                    )}
                  </Flex>
                </Row>
              )}
              <p>
                {item?.acc_desc ? item.acc_desc.slice(0, 150) + '...' : item?.acc_desc}
                <Link
                  to={type === 'YellowPage' ? `/eDirectory/${item.id}` : `/professionalService/${item.id}`}
                  state={{ pathname: location.pathname, search: location.search }}
                >
                  Read more
                </Link>
              </p>

              <div className="keyword">{categoryGenerator(item.acc_category, item.acc_prodService)}</div>
            </Content>
            {!isTablet && (
              <div className="detail">
                <Row>
                  {/* <Flex alignItems={'flex-end'}>
                    {gradeGenerator(item.stars).map((star, index) => (
                      <img width="33px" key={'grade' + item.acc_coname + index} src={star} alt="grade" />
                    ))}
                    {` (${item.rating_count})`}
                  </Flex> */}
                  <Flex marginLeft={'auto'} gridGap={10}>
                    {item.id !== userData.id ? (
                      <IconBtn onClick={() => goToChat(item.id)}>
                        <img src="/images/yellowpage/chat.svg" alt="" />
                      </IconBtn>
                    ) : (
                      <IconBtn className="disable">
                        <img src="/images/yellowpage/chat.svg" alt="" />
                      </IconBtn>
                    )}
                    {item.acc_website && (
                      <IconBtn>
                        <a href={checkWebsiteLink(item.acc_website)} target="_blank" rel="noreferrer">
                          <img src="/images/yellowpage/global.svg" alt="" />
                        </a>
                      </IconBtn>
                    )}
                  </Flex>
                </Row>

                <Row>
                  {item?.acc_tel && (
                    <div className="col">
                      <div className="icon">
                        <img width={24} src="/images/yellowpage/search/tel.svg" alt="" />
                      </div>
                      <div className="info">
                        ({item?.acc_countryCode}) {item?.acc_tel}
                      </div>
                    </div>
                  )}
                </Row>

                {item.com_email && (
                  <Row>
                    <div className="col">
                      <div className="icon">
                        <img width={24} src="/images/yellowpage/search/mail.svg" alt="" />
                      </div>
                      <div className="info">{item.com_email}</div>
                    </div>
                  </Row>
                )}

                {item?.acc_address && (
                  <Row style={{ marginBottom: 0 }}>
                    <div className="col">
                      <div className="icon">
                        <img width={24} src="/images/yellowpage/search/address.svg" alt="" />
                      </div>
                      <div className="info">{item.acc_address?.split('@@_@@').join(' ')}</div>
                    </div>
                  </Row>
                )}
              </div>
            )}
          </CardWrapper>
        ))}

      {showData && data.length === 0 && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Text>No result found</Text>
        </div>
      )}
      {data.length > 0 && showData && (
        <Flex paddingBottom={'20px'} justifyContent={'space-between'}>
          <Text color={theme.colors.gray300}>
            {t('buttons.showing')} {(page - 1) * perPage + 1} {t('buttons.to')}{' '}
            {totalData - page * perPage < 0 ? totalData : page * perPage} {t('buttons.of')} {totalData}{' '}
            {t('buttons.records')}
          </Text>
          <Flex gridGap={'10px'}>
            <PageBtnWrapper className={page === 1 ? 'disabled' : ''} onClick={() => pageHandler(page - 1, 'prev')}>
              <Page.PagePrevSVG />
            </PageBtnWrapper>
            {totalData - page * perPage > 0 && (
              <PageBtnWrapper onClick={() => pageHandler(page + 1, 'next')}>
                <Page.PageNextSVG />
              </PageBtnWrapper>
            )}
          </Flex>
        </Flex>
      )}
      {type === 'LawFirmsList' && (
        <Text>
          {t('pages.yellowPage.CNSearchBar.disclaimer.title')}: <br />
          {t('pages.yellowPage.CNSearchBar.disclaimer.content')}
        </Text>
      )}
    </SectionContainer>
  );
};

export default List;
