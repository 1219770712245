import {
  UserInfoBadge,
  SupplementalInfoBadge,
  IdentificationCheckBadge,
  ECertBadge,
  CreditReportBadge,
} from '@/components/UI/Badge';
import { Flex } from '@/layout';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

interface ShowBadgeProps {
  level: string;
}

const TipWrapper = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.fontSizes.base,
  },
}));

export const ShowBadge = ({ level }: ShowBadgeProps) => {
  switch (level) {
    case '1':
      return <UserInfoBadge />;
    case '2':
      return (
        <Flex gridGap={'3px'}>
          <UserInfoBadge />
          <SupplementalInfoBadge />
        </Flex>
      );
    case '3':
      return (
        <Flex gridGap={'3px'}>
          <UserInfoBadge />
          <SupplementalInfoBadge />
          <IdentificationCheckBadge />
        </Flex>
      );
    case '4':
      return (
        <Flex gridGap={'3px'}>
          <UserInfoBadge />
          <SupplementalInfoBadge />
          <IdentificationCheckBadge />
          <ECertBadge />
        </Flex>
      );
    case '5':
      return (
        <Flex gridGap={'3px'}>
          <UserInfoBadge />
          <SupplementalInfoBadge />
          <IdentificationCheckBadge />
          <ECertBadge />
          {/* <CreditReportBadge /> */}
        </Flex>
      );
    default:
      return <></>;
  }
};
