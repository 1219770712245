const categoryEn = [
  'Legal Services',
  'Film and TV Industry',
  'Agriculture',
  'Construction',
  'Education',
  'Electronics',
  'Energy',
  'Entertainment',
  'Financial Services',
  'Healthcare',
  'Hospitality and Food Services',
  'Import/Export Trade and Wholesale',
  'Information Technology',
  'Machinery/Equipment',
  'Manufacturing',
  'Media and Communications',
  'Mining & Quarrying',
  'Pharmaceutical',
  'Business Services',
  'Real Estate',
  'Retail',
  'Social and Personal Services',
  'Textiles/Garments',
  'Transportation, Storage and Courier Services',
  'Utilities',
  'Waste Management',
  'Accounting',
  'Banking',
  'Insurance',
  'Other',
];

export default categoryEn;
